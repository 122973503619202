<!--
 * @Description:客服用户服务管理 客服工单管理  工单信息管理 workOrderInfoManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:39:14
 * @LastEditors: hope0928 houjinduo1007@163.com
-->

<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="工单编号">
              <el-input v-model="searchForm.id"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderId"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="工单状态">
              <el-select v-model="searchForm.checkState"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in orderStateList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="创建时间">
              <el-date-picker v-model="Time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>工单信息管理</span>
         <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="工单状态">
              <template slot-scope="scope">
                <span v-if="scope.row.checkStateName === '待处理'"
                      style="color:#F63B24 ">{{scope.row.checkStateName}}</span>
                <span v-if="scope.row.checkStateName === '已处理'"
                      style="color:#00D03F">{{scope.row.checkStateName}}</span>
                <span v-if="scope.row.checkStateName === '已驳回'"
                      style="color:#3C6A8B">{{scope.row.checkStateName}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDetails(scope.row)">查看详情</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             v-if="scope.row.checkStateName==='待处理'"
                             @click="handelClickDispose(scope.row)">审 核</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog title="申诉详情"
               :visible.sync="viewDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="getWorkOrderInfo">
      <el-row class="dialogLine">
        <el-row class="dialogLineOne">
          <el-row class="dialogLineOne-left">
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单编号:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.orderId:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">申诉时间:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealTime:""}}</el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftthree">
              <el-row class="lineTitle">申诉描述:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealReason:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">车牌号:</el-row>
              <el-row class="lineText">
                {{viewOrderAdviseList?viewOrderAdviseList.plateNumber:""}}({{viewOrderAdviseList?viewOrderAdviseList.numberPlateColorCodeName:""}})</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">计费时长:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.billingDurationCalc:0}}分钟</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单应收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewOrderAdviseList?Number(viewOrderAdviseList.receivableMoneyAmount):0}} 元</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单实收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewOrderAdviseList?Number(viewOrderAdviseList.receivedMoneyAmount):0}} 元</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-mid">
            <el-row class="lineTitleIn">入场取证</el-row>
            <el-row class="lineText">{{inevidenceCreateTime?inevidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="inevidencePic"
                   :src="$imgBaseUrl + inevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-right">
            <el-row class="lineTitleOut">出场取证</el-row>
            <el-row class="lineText">{{outevidenceCreateTime?outevidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="outevidencePic"
                   :src="$imgBaseUrl + outevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
        </el-row>
        <el-row class="dialogLineTwo"></el-row>
        <el-row class="dialogLineThree">
          <el-row class="lineOne">
            <el-row class="lineTitle">处理备注:</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.dealBak?viewOrderAdviseList.dealBak:""}}</el-row>
          </el-row>
          <el-row class="lineOne">
            <el-row class="lineTitle">工单上报原因:</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.reportReason?viewOrderAdviseList.reportReason:""}}</el-row>
          </el-row>
          <el-row class="lineTwo">
            <el-row class="lineTitle">处理人:</el-row>
            <el-row class="lineLeftText">{{viewOrderAdviseList.dealUsername?viewOrderAdviseList.dealUsername:""}}</el-row>
            <el-row class="lineTitle">处理时间</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.dealTime?viewOrderAdviseList.dealTime:""}}</el-row>
          </el-row>
          <el-row class="lineOne">
            <el-row class="lineTitle">退款金额:</el-row>
            <el-row class="lineText"
                    style="color:#FF4A00">{{viewOrderAdviseList.refundMoney ?viewOrderAdviseList.refundMoney : 0}} 元</el-row>
          </el-row>
          <el-row class="lineTwo">
            <el-row class="lineTitle">审核人:</el-row>
            <el-row class="lineLeftText">{{viewOrderAdviseList.checkUser?viewOrderAdviseList.checkUser:""}}</el-row>
            <el-row class="lineTitle">审核时间:</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.checkTime?viewOrderAdviseList.checkTime:""}}</el-row>
          </el-row>
        </el-row>
        <el-row class="lineButton">
          <el-button type="primary"
                     @click="viewDialogVisible=false">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <el-dialog title="申诉处理"
               :visible.sync="handleDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="getWorkOrderInfo">
      <el-row class="dialogLine">
        <el-row class="dialogLineOne">
          <el-row class="dialogLineOne-left">
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单编号:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.orderId:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-lefttwo">
              <el-row class="lineTitle">申诉时间:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealTime:""}}</el-row>
              <el-row class="lineTitle">申诉类型:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealTypeName:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftthree">
              <el-row class="lineTitle">申诉描述:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.appealReason:""}}</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">车牌号:</el-row>
              <el-row class="lineText">
                {{viewOrderAdviseList?viewOrderAdviseList.plateNumber:""}}({{viewOrderAdviseList?viewOrderAdviseList.numberPlateColorCodeName:""}})</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">计费时长:</el-row>
              <el-row class="lineText">{{viewOrderAdviseList?viewOrderAdviseList.billingDurationCalc:0}}分钟</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单应收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewOrderAdviseList?Number(viewOrderAdviseList.receivableMoneyAmount):0}} 元</el-row>
            </el-row>
            <el-row class="dialogLineOne-leftone">
              <el-row class="lineTitle">订单实收金额:</el-row>
              <el-row class="lineText"
                      style="color:#C09200">{{viewOrderAdviseList?Number(viewOrderAdviseList.receivedMoneyAmount):0}} 元</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-mid">
            <el-row class="lineTitleIn">入场取证</el-row>
            <el-row class="lineText">{{inevidenceCreateTime?inevidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="inevidencePic"
                   :src="$imgBaseUrl + inevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
          <el-row class="dialogLineOne-right">
            <el-row class="lineTitleOut">出场取证</el-row>
            <el-row class="lineText">{{outevidenceCreateTime?outevidenceCreateTime:""}}</el-row>
            <el-row class="lineImg">
              <img v-if="outevidencePic"
                   :src="$imgBaseUrl + outevidencePic"
                   alt="">
              <el-row v-else
                      class="lineImgText">暂无取证图片</el-row>
            </el-row>
          </el-row>
        </el-row>
        <el-row class="dialogLineTwo"></el-row>
        <el-row class="dialogLineThree">
          <el-row class="lineOne">
            <el-row class="lineTitle">处理备注:</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.dealBak?viewOrderAdviseList.dealBak:""}}</el-row>
          </el-row>
          <el-row class="lineOne">
            <el-row class="lineTitle">工单上报原因:</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.reportReason?viewOrderAdviseList.reportReason:""}}</el-row>
          </el-row>
          <el-row class="lineTwo">
            <el-row class="lineTitle">处理人:</el-row>
            <el-row class="lineLeftText">{{viewOrderAdviseList.dealUsername?viewOrderAdviseList.dealUsername:""}}</el-row>
            <el-row class="lineTitle">处理时间</el-row>
            <el-row class="lineText">{{viewOrderAdviseList.dealTime?viewOrderAdviseList.dealTime:""}}</el-row>
          </el-row>
          <el-row class="lineOne">
            <el-row class="lineText"
                    style="color:#FF4A00;">
              <el-form :model="popupForm">
                <el-form-item label-width="115px"
                              label="免单驳回备注:">
                  <el-input placeholder="请输入"
                            v-model="popupForm.checkBak">
                  </el-input>
                </el-form-item>
              </el-form>
            </el-row>
          </el-row>
        </el-row>
        <el-row class="lineButton">
          <el-button type="success"
                     @click="handleClickNormalRefund">改单处理</el-button>
          <el-button type="success"
                     @click="handleClcikReplaceRefund">免单</el-button>
          <el-button type="primary"
                     @click="handleClickReject">驳回</el-button>
          <el-button type="primary"
                     @click="handleDialogVisible=false">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <el-dialog title="改单处理"
               :visible.sync="alertDialogVisible"
               :close-on-click-modal="false"
               append-to-body
               @close="getWorkOrderInfo">
      <el-row>
        <el-form :model="dealForm">
          <el-row style="display:flex">
            <el-form-item label-width="115px"
                          label="车牌号:">
              <el-input placeholder="请输入"
                        v-model="dealForm.newPlateNumber">
              </el-input>
            </el-form-item>
            <el-form-item style="margin-left:100px"
                          label-width="115px"
                          label="车牌颜色:">
              <el-select v-model="dealForm.newNumberPlateColorCode"
                         placeholder="请选择">
                <el-option v-for="item in colorList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row style="display:flex;margin-top:20px">
            <el-form-item label-width="115px"
                          label="进场时间:">
              <el-date-picker v-model="dealForm.newInTime"
                              type="datetime"
                              format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item style="margin-left:100px"
                          label-width="115px"
                          label="离场时间:">
              <el-date-picker v-model="dealForm.newOutTime"
                              type="datetime"
                              format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-row>
          <el-row style="display:flex;margin-top:20px">
            <el-form-item label-width="115px"
                          label="审核备注:">
              <el-input placeholder="请输入"
                        v-model="dealForm.checkBak">
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <el-row class="lineButton">
          <el-button type="success"
                     @click="confrimGai">确认</el-button>
        </el-row>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import $axios from "axios";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'id',
          label: '工单编号',
        },
        {
          prop: 'orderId',
          label: '订单编号',
        },
        {
          prop: 'appealTypeName',
          label: '申诉类型',
        },
        {
          prop: 'reportTime',
          label: '工单创建时间',
        }
      ],
      //表格数据
      tableList: {
        list: []
      },
      //查询表单
      searchForm: {
        id: "",
        orderId: "",
        checkState: "",
      },
      Time: [],// 顶部查询时间
      pageNum: 1,
      pageSize: 15, // 分页
      colorList: [],//车牌颜色下拉框
      orderComplianTypeList: [],  // 申诉类型下拉框
      orderStateList: [],  // 工单状态下拉框
      viewDialogVisible: false, // 查看详情弹窗
      viewOrderAdviseList: [], // 查看详情列表
      inevidenceCreateTime: "",//入场时间
      inevidencePic: "",//入场图片
      outevidenceCreateTime: "",//出场时间
      outevidencePic: "",//出场图片
      handleDialogVisible: false,//审核弹窗
      popupForm: {},//审核表单
      alertDialogVisible: false, //改单处理
      dealForm: {},// 改单处理
      workid: "", // 工单编号
      orderId: "" // 订单编号
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryDictList()
    this.getColorDict()
  },
  //方法集合
  methods: {
    // 改单处理
    handleClickNormalRefund () {
      this.dealForm = {}
      this.alertDialogVisible = true
    },
    // 确认改单
    confrimGai () {
      let info = {
        id: this.workid,
        newPlateNumber: this.dealForm.newPlateNumber,
        newNumberPlateColorCode: this.dealForm.newNumberPlateColorCode,
        newInTime: this.dealForm.newInTime,
        newOutTime: this.dealForm.newOutTime,
        checkBak: this.dealForm.checkBak
      }
      this.$workOrderInfoManage.dealWorkOrder(info).then(() => {
        this.$message({ message: '改单成功', type: 'success' });
        this.getWorkOrderInfo()
        this.alertDialogVisible = false
      }).catch(error => {
        this.$message({ message: error.resultMsg, type: 'error' });
      })
    },
    // 免单
    handleClcikReplaceRefund () {
      this.$confirm('是否确定免单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          id: this.workid,
          orderId: this.orderId,
          checkBak: this.popupForm.checkBak
        }
        this.$workOrderInfoManage.invalidateOrder(info).then(() => {
          this.$message({
            message: '免单成功',
            type: 'success'
          })
          this.handleDialogVisible = false
          this.getWorkOrderInfo()
        }).catch(err => {
          this.$message({ message: err.resultMsg, type: 'error' });
        })
      })
    },
    // 驳回
    handleClickReject () {
      this.$confirm('是否确定驳回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          id: this.workid,
          checkBak: this.popupForm.checkBak
        }
        this.$workOrderInfoManage.rejectWorkOrder(info).then(() => {
          this.$message({ message: '驳回成功！', type: 'success' });
          this.handleDialogVisible = false
          this.getWorkOrderInfo()
        }).catch(err => {
          this.$message({ message: err.resultMsg, type: 'error' });
        })
      })
    },
    // 查看详情
    handleClickDetails (row) {
      this.viewDialogVisible = true
      let info = {
        id: row.id
      }
      this.$workOrderInfoManage.getInfoById(info).then(res => {
        this.viewOrderAdviseList = res.resultEntity
        this.inevidenceCreateTime = res.resultEntity.orderEvidence[0].evidenceCreateTime
        this.inevidencePic = res.resultEntity.orderEvidence[0].evidencePic
        this.outevidenceCreateTime = res.resultEntity.orderEvidence[1].evidenceCreateTime
        this.outevidencePic = res.resultEntity.orderEvidence[1].evidencePic
      })
    },
    // 审核
    handelClickDispose (row) {
      this.handleDialogVisible = true
      let info = {
        id: row.id
      }
      this.orderId = row.orderId
      this.workid = row.id
      console.log(row);
      this.$workOrderInfoManage.getInfoById(info).then(res => {
        this.viewOrderAdviseList = res.resultEntity
        console.log(this.viewOrderAdviseList, " this.viewOrderAdviseList");
        if (res.resultEntity.orderEvidence[0]) {
          this.inevidenceCreateTime = res.resultEntity.orderEvidence[0].evidenceCreateTime
          this.inevidencePic = res.resultEntity.orderEvidence[0].evidencePic
        }
        if (res.resultEntity.orderEvidence[1]) {
          this.outevidenceCreateTime = res.resultEntity.orderEvidence[1].evidenceCreateTime
          this.outevidencePic = res.resultEntity.orderEvidence[1].evidencePic
        }
      })
    },
     // 导出
    exportFile () {
      const loading = this.$loading({
        target: '#monitorBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$axios.post(this.$downloadOrderBaseUrl + "WorkOrder/exportIn", this.searchForm , {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "客服工单表.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
        loading.close()
      })
    },
    // 初始化数据
    getWorkOrderInfo () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$workOrderInfoManage.getWorkOrderList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getWorkOrderInfo()
    },
    //查询
    handleClickSearch () {
      this.pageNum = 1;
      this.listMap.pageNum = 1;
      if (this.Time) {
        this.searchForm.startTime = this.Time[0]
        this.searchForm.endTime = this.Time[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.getWorkOrderInfo()
    },
    //重置
    resetForm () {
      this.pageNum = 1;
      this.listMap.pageNum = 1;
      this.Time = []
      this.searchForm = {}
      this.getWorkOrderInfo()
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      }).catch((error) => {
        console.log('失败', error)
      })
    },
    // 工单状态 工单类型
    queryDictList () {
      let info1 = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [{
          colName: 'parent_id',
          value: 'F8898BDD4BCF4W12ABA91DB0DEF',
        },],

      }
      let info2 = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [{
          colName: 'parent_id',
          value: 'F8898BDD4BCF4W12ABA91DBUIUIUIU',
        },],
      }
      $axios.all([this.$queryDict.queryDict(info1), this.$queryDict.queryDict(info2)]).then(([res1, res2]) => {
        this.orderComplianTypeList = res2.resultEntity
        this.orderStateList = res1.resultEntity
        this.getWorkOrderInfo()
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
//@import url(); 引入公共css类
//斑马线
/deep/.warning-row {
  background: #063159 !important;
}
/deep/.success-row {
  background: #032244 !important;
}
// 最外层div
.mainBody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      margin-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dialogLine {
  /deep/ .el-form-item__label {
    text-align: left !important;
  }
  .dialogLineOne {
    width: 100%;
    height: 260px;
    display: flex;
    .dialogLineOne-left {
      width: 60%;
      height: 260px;
      .dialogLineOne-leftone {
        width: 80%;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 25%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 75%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
      .dialogLineOne-lefttwo {
        width: 100%;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 40%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 60%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
      .dialogLineOne-leftthree {
        width: 100%;
        height: 35px;
        margin-top: 10px;
        display: flex;
        .lineTitle {
          width: 20%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #70aee8;
        }
        .lineText {
          width: 80%;
          height: 20px;
          line-height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #bae8ff;
        }
      }
    }
    .dialogLineOne-mid {
      width: 18%;
      height: 240px;
      margin-top: 10px;
      margin-left: 2%;
      border: 1px solid#0F8AD4;
      .lineTitleIn {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #6de721;
        margin-left: 10%;
      }
      .lineText {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ffffff;
        margin-left: 10%;
      }
      .lineImg {
        width: 100%;
        height: 80%;
        img {
          width: 100%;
          height: 100%;
        }
        .lineImgText {
          text-align: center;
          color: #0f8ad4;
        }
      }
    }
    .dialogLineOne-right {
      width: 18%;
      height: 240px;
      margin-top: 10px;
      margin-left: 2%;
      border: 1px solid#0F8AD4;
      .lineTitleOut {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #ed740e;
        margin-left: 10%;
      }
      .lineText {
        width: 90%;
        height: 10%;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ffffff;
        margin-left: 10%;
      }
      .lineImg {
        width: 100%;
        height: 80%;
        img {
          width: 100%;
          height: 100%;
        }
        .lineImgText {
          text-align: center;
          color: #0f8ad4;
        }
      }
    }
  }
  .dialogLineTwo {
    width: 100%;
    border: 1px solid #004474;
    margin-top: 10px;
  }
  .dialogLineThree {
    width: 100%;
    .lineOne {
      display: flex;
      margin-top: 10px;
      .lineTitle {
        width: 12.5%;
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #70aee8;
      }
      .lineText {
        width: 87.5%;
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #bae8ff;
      }
    }
    .lineTwo {
      display: flex;
      margin-top: 10px;
      .lineTitle {
        width: 12.5%;
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #70aee8;
      }
      .lineLeftText {
        width: 17.5%;
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #bae8ff;
      }
      .lineText {
        width: 50%;
        height: 20px;
        line-height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #bae8ff;
      }
    }
  }
  .lineButton {
    display: flex;
    margin-top: 40px;
    float: right;
  }
}
.lineButton {
  display: flex;
  margin-top: 20px;
  float: right;
}
</style>